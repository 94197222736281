import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { formatCurrency } from '../../utils/generalHelper';

const CardModal = (props) => {

    const item = props?.data
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    return (

        <Modal
            show={props.show}
            onHide={props.onHide}
        // size=''
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body">
                <div className="sc-card-product user-info no-box-shadown mb-0">

                    <div className="card-media">
                        <img src={item?.image_link} alt={item?.title} />
                        {/* <Link to="/login" className="wishlist-button heart"><span className="number-like">{item.wishlist}</span></Link> */}
                        <div className="featured-countdown category">
                            <span className="tags">Property</span>
                        </div>
                        {/* <div className="button-place-bid">
                            <button  className="sc-button style-place-bid style bag fl-button pri-3"><span>Place Bid</span></button>
                        </div> */}
                    </div>
                    <div className="card-title">
                        <h3>{item?.title}</h3>
                    </div>
                    <div className="meta-info">
                        <div className="author">
                            <div className="avatar">
                                <img src={item?.image_link} alt="axies" />
                            </div>
                            <div className="info">
                                <span>Seller</span>
                                <h4> {item?.seller_name}
                                </h4>
                            </div>
                        </div>
                        <div className="price">
                            <span>Price</span>
                            <h5> {formatCurrency(item?.price)}</h5>
                        </div>
                    </div>
                    <div className="card-body mt-5">
                        <p className='request-description'>{item?.description}</p>
                    </div>
                  
                    <div className="mt-5">
                        <a class="sc-button loadmore style bag fl-button pri-3 w-100 text-center" href="/wallet-connect"><span>Request Buy</span></a>
                    </div>
                </div>
            </div>
        </Modal>

    );
};

export default CardModal;
