import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DarkMode from '../components/header/DarkMode';
import axios from 'axios';

const apiHost = process.env.REACT_APP_API_HOST;
const RequestBuy = () => {
    // get query params id
    const location = useLocation();
    // Function to get query parameters
    const getQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        const params = {};
        queryParams.forEach((value, key) => {
            params[key] = value;
        });
        return params;
    };

    const queryParams = getQueryParams();

    // add validation to check if query params are empty
    useEffect(() => {
        if (!queryParams?.id || !queryParams?.name || !queryParams?.phone) {
            console.error('No query params found');
            window.location.href = '/';
        }
    }, [queryParams]);

    useEffect(() => {
        const makePostRequest = async () => {
            try {
                const response = await axios.post(`${apiHost}/webhook`, {
                    uuid: queryParams?.uuid, // Use query param or default to 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                
            } catch (error) {
                console.error('Error making POST request:', error);
            }
        };

        makePostRequest();
    }, [queryParams]);


    const body = document.body
    body.classList.add('under-same')
    return (
        <div>
            {/* <Header /> */}
            <DarkMode />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Request Accepted</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-no-result tf-section vh-100">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">
                                We will contact you soon!
                            </h2>
                            <h5 className="sub-title help-center mg-bt-32 ">
                                Thank you for reaching out. If you have any further questions, feel free to contact us.
                            </h5>
                            <div className="text-center">
                                <Link to="/" className="sc-button header-slider style style-1 bag fl-button pri-1"><span>Back To Home
                                </span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default RequestBuy;
