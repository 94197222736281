export const saveToken = async (token) => {
    localStorage.setItem('authToken', token);
}

export const getToken = async() => {
    return localStorage.getItem('authToken')
}

export const removeToken = async() => {
    localStorage.removeItem('authToken')
}