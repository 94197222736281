import React, { useState, useEffect } from 'react';
import Header from '../components/header/HeaderStyle2';
import ItemContent from '../components/layouts/home-8/ItemContent';
import SideBar from '../components/layouts/home-8/SideBar';
import CardModalLogin from '../components/layouts/CardModalLogin';
import { getToken } from '../utils/tokenHelper';
import axios from 'axios';

const Home08 = () => {
    const body = document.body
    body.classList.add('under-same')
    const apiHost = process.env.REACT_APP_API_HOST
    const [modalDataLogin, setModalDataLogin] = useState(null);
    const [isAuthenticated, setAuthenticated] = useState(null)
    const [userName, setUserName] = useState(false)
    const [isFromLogout, setFromLogout] = useState(false)
    const [productData, setProductData] = useState([])
    const [dataPanels, setDataPanel] = useState(
        [
            {
                id: 1,
                dataContent: productData
            }
        ]
    );
    

    // Fetch data to api
    useEffect(() => {
        const getProduct = async () => {
            try {
                const token = await getToken();
                const response = await axios.get(`${apiHost}/products`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const products = response.data.data
                setProductData(products)
            } catch (error) {
                console.log('error', error)
            }
        }
        getProduct()
    }, []);


    const me = async () => {
        try {
            const token = await getToken();
            const response = await axios.get(`${apiHost}/me`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data?.success) {
                setAuthenticated(true)
                setUserName(response.data?.data?.full_name)
                setModalDataLogin(false);
            } else {
                setAuthenticated(false)
                setModalDataLogin(true);
            }

        } catch (error) {
            setAuthenticated(false)
            setModalDataLogin(true);
        }
    };

    useEffect(() => {
        me()
    }, [])


    const [searchQuery, setSearchQuery] = useState('');
    // handle radio
    const [radio, setRadio] = useState('');

    // Detects inputValue state changes
    useEffect(() => {
        if (searchQuery.length > 2) {
            const searchResults = productData.filter(item =>
                Object.values(item).some(value =>
                    value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
            )

            if (searchResults.length > 0) {
                setDataPanel(d => [{ ...d[0], dataContent: searchResults }])
            } else {
                // should be empty
                setDataPanel(d => [{ ...d[0], dataContent: [] }])
            }
        } else {
            setDataPanel(d => [{ ...d[0], dataContent: productData }])
        }


    }, [searchQuery]); // Runs only when inputValue changes


    useEffect(() => {
        if(Array.isArray(productData) && productData.length > 0) {
            setDataPanel(d => [{ ...d[0], dataContent: productData }])
        }
    }, [productData])

    useEffect(() => {
        if (radio.length > 0) {
            const radioResults = productData.filter(item => radio.includes(item.category));
            if (radioResults.length > 0) {
                setDataPanel(d => [{ ...d[0], dataContent: radioResults }])
            } else {
                setDataPanel(d => [{ ...d[0], dataContent: [] }])
            }
        } else {
            setDataPanel(d => [{ ...d[0], dataContent: productData }])
        }
    }, [radio]);

    return (
        <div className='home-8'>
            <Header setSearchQuery={setSearchQuery} />
            <section className="tf-item tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-box-17">
                            <SideBar 
                                setRadio={setRadio} 
                                isAuthenticated={isAuthenticated} 
                                name={userName} 
                                setAuthenticated={setAuthenticated} 
                                setFromLogout={setFromLogout}
                            />
                        </div>

                        <div className="col-box-83">
                            <ItemContent dataPanels={dataPanels} />
                        </div>
                    </div>
                </div>
                <CardModalLogin
                    show={modalDataLogin}
                    onHide={setModalDataLogin}
                    data={modalDataLogin}
                    me={me}
                    isAuthenticated={isAuthenticated}
                    isFromLogout={isFromLogout}
                />
            </section>
        </div>
    );
}

export default Home08;