import React, {useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import logoProfile from '../../assets/images/logo/assero_profile.png'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { saveToken } from '../../utils/tokenHelper';

const CardModalLogin = ({show, onHide, data, me, isAuthenticated, isFromLogout}) => {

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [pin, setPin] = useState('');
    const [RegisterErrorMessage, setRegisterError] = useState(false)
    const [disabledButtonRegister, setDisabledButtonRegister] = useState(false)
    const [isRegister, setRegister] = useState(true)
    const [doneRegister, setDoneRegister] = useState(false)
    const [isLogin, setLogin] = useState(false)
    const [loginErrorMessage, setLoginError] = useState(false)
    const [disabledButtonLogin, setDisabledButtonLogin] = useState(false)

    const apiHost = process.env.REACT_APP_API_HOST

    useEffect(() => {
        console.log(isFromLogout)
        if(isAuthenticated){
            onHide(false)
        }else{
            onHide(true)
        }

        if(isFromLogout){
            setLogin(true)
            setRegister(false)
            setDoneRegister(false)
        }else{
            setRegister(true)
            setDoneRegister(false)
            setLogin(false)
        }
        
    }, [isAuthenticated])
    
    const register = async (name, pin, phone) => {
        setDisabledButtonRegister(true)
        // hit endpoint to register
        try{
            const response = await axios.post(`${apiHost}/register`, {
                'full_name': name,
                'pin': pin,
                'phone':phone
            }, {
                headers:{
                    'Content-Type': 'application/json', // Specify the content type
                    'Accept': 'application/json',  
                }
            })

            if(response.data?.success){
                setRegister(false)
                setDoneRegister(response.data?.data?.invite)
            }else{
                setRegisterError(response.data?.message)
            }
            
        }catch(error){
            // Handle the error response
            if (axios.isAxiosError(error)) {
                // The request was made and the server responded with a status code
                if (error.response) {
                    setRegisterError(error.response?.data?.message)
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received:', error.request);
                } else {
                    // Something happened in setting up the request
                    console.error('Error Message:', error.message);
                }
            } else {
                setRegisterError(error)
                console.error('Unexpected Error:', error);
            }
            setDisabledButtonRegister(false)
        }finally{
            setDisabledButtonRegister(false)
        }
    }

    const login = async (name, pin) => {
        setDisabledButtonLogin(true)
        try{
            const response = await axios.post(`${apiHost}/login`, {
                'full_name': name,
                'pin': pin,
            }, {
                headers:{
                    'Content-Type': 'application/json', // Specify the content type
                    'Accept': 'application/json',  
                }
            })

            if(response.data?.success){
                const token = response.data?.data?.token
                // Save token
                if(token){
                    saveToken(token)
                    setLogin(false)
                    setLogin(false)
                    setRegister(false)
                    setDisabledButtonLogin(true)
                    setDisabledButtonRegister(true)
                    setLoginError(false)
                    setRegisterError(false)
                    setDoneRegister(false)
                    onHide()
                    me()
                }


            }else{
                setLoginError(response.data?.message)
            }
            
        }catch(error){
            // Handle the error response
            if (axios.isAxiosError(error)) {
                // The request was made and the server responded with a status code
                if (error.response) {
                    setLoginError(error.response?.data?.message)
                } else if (error.request) {
                    // The request was made but no response was received
                    setLoginError(`No response received: ${error.request}`);
                } else {
                    // Something happened in setting up the request
                    setLoginError(`Error Message: ${error.message}`);
                }
            } else {
                setLoginError(error)
            }
            setDisabledButtonLogin(false)
        }finally{
            setDisabledButtonLogin(false)
        }
    }

    const clickLogin = async () => {
        setRegister(false)
        setDoneRegister(false)
        setLogin(true)
    }

    const clickRegister = async () => {
        setLogin(false)
        setDoneRegister(false)
        setRegister(true)
    }

    return (

        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
        >
            {/* <Modal.Header closeButton></Modal.Header> */}
            <div className="modal-body">
                <div className="row">

                    {/* Register Content */}
                    { isRegister && 
                    <div className="space-y-20 pd-20 px-5 col-12">
                        <div className="d-flex align-items-center">
                            <div className='ml-3'>
                            </div>

                        </div>
                        <div className="text-center">
                            <img id="logo_login" src={logoProfile} alt="Assero: Deals Done Right" />
                        </div>
                        <h4 className="text-center">Join Assero: Deals Done Right!</h4>
                        <p className="text-left h5 text-light pt-3">By clicking “Sign up”, you agree to our terms of service and acknowledge you have read our privacy policy.
                        </p>
                        {RegisterErrorMessage &&
                        <div className="p-4 rounded-4 alert alert-danger text-danger h4">
                            {RegisterErrorMessage}
                        </div>}
                        <div className="form-inner pt-3">
                            <form id="contactform">
                                <input type="text"
                                    placeholder="Your Full Name" tabIndex="1"
                                    name='full_name'
                                    aria-required="true"
                                    required=""
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} />
                                <input type="text" placeholder="Your Phone Number" name='phone' value={phone} tabIndex="2" onChange={(e) => setPhone(e.target.value)} />
                                <input type="text" className='mb-3' tabIndex="3" name='pin' placeholder='Set Your Pin' value={pin} onChange={(e) => setPin(e.target.value)} />

                                <div className="mt-4 pb-3">
                                    <button type='button' disabled={disabledButtonRegister} className="submit" onClick={() => register(name, pin, phone)} tabIndex="4">Sign Up</button>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-4 mb-2 hover-pointer" onClick={() => clickLogin()}>
                                    <p className='h4'>Already have an account? </p>
                                    <p className='h4'><Link to="#" className="h4 pl-2 text-info" onClick={() => clickLogin()}>Login</Link></p>
                                </div>
                            </form>
                        </div>

                    </div> }
                    {/* End Register Content */}

                    {/* Done Register Content */}
                    { doneRegister &&
                    <div className="space-y-20 pd-20 px-5 col-12 mt-4">
                        <div className="text-center">
                            <img id="log_login" src={logoProfile} alt="Assero: Deals Done Right" className='w-25' />
                            <div className="text-center mt-5 mb-5">
                            <h4 className="text-center pb-3">Sign-Up Success</h4>
                            <h5 className='pb-3'> Please Verify Your Account!</h5>
                                <Link to={`${doneRegister}`} target='_blank' className="btn btn-primary w-50" >Verify Account</Link>
                            </div>
                        </div>
                    </div>}
                    {/* End Done Register Content */}

                    {/* Login Content */}
                    { isLogin && 
                    <div className="space-y-20 pd-20 px-5 col-12">
                        <div className="d-flex align-items-center">
                            <div className='ml-3'>
                            </div>
                        </div>
                        <div className="text-center">
                            <img id="logo_login" src={logoProfile} alt="Assero: Deals Done Right" />
                        </div>
                        <h4 className="text-center">Login Assero: Deals Done Right!</h4>
                        {loginErrorMessage &&
                        <div className="p-4 rounded-4 alert alert-danger text-danger h4">
                            {loginErrorMessage}
                        </div>}
                        <div className="form-inner pt-3">
                            <form id="contactform">
                                <input type="text"
                                    placeholder="Your Full Name" tabIndex="1"
                                    name='full_name'
                                    aria-required="true"
                                    required=""
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} />
                                <input type="text" className='mb-3' tabIndex="3" name='pin' placeholder='Set Your Pin' value={pin} onChange={(e) => setPin(e.target.value)} />

                                <div className="mt-4 pb-3">
                                    <button type='button' disabled={disabledButtonLogin} className="submit" onClick={() => login(name, pin)} tabIndex="4">Log In</button>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-4 mb-2 hover-pointer" onClick={() => clickRegister()}>
                                    <p className='h4'>Don't have an account?  </p>
                                    <p className='h4'><Link to="#" className="h4 pl-2 text-info" onClick={() => clickRegister()}>Sign Up</Link></p>
                                </div>
                            </form>
                        </div>

                    </div> }
                    {/* Done Login Content */}
                </div>
            </div>
        </Modal>
    );
};

export default CardModalLogin;
