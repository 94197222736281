import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap-accordion'
import { removeToken } from '../../../utils/tokenHelper';
import { getGreeting } from '../../../utils/generalHelper';

const SideBar = ({ setRadio, isAuthenticated, name, setAuthenticated, setFromLogout}) => {
    const [dataCate] = useState(
        [
            {
                title: 'Categories',
                content: [
                    {
                        field: 'Property',
                        checked: ''
                    },
                    {
                        field: 'Car',
                        checked: ''
                    },
                    {
                        field: 'Motorcycle',
                        checked: ''
                    },
                    {
                        field: 'Utility',
                        checked: ''
                    }
                ]
            }
        ]
    )

    const logout = async() => {
        setAuthenticated(false)
        setFromLogout(true)
        removeToken()
    }

    const handleRadio = (e, field) => {
        setRadio((prev) => {
            if (prev.includes(field)) {
                // Remove if already selected
                return prev.filter(item => item !== field);
            } else {
                // Add to selection
                return [...prev, field];
            }
        });
    }
    return (
        <div id="side-bar" className="side-bar style-3 item">
            {isAuthenticated && 
            <div className="sc-card-product explode style2 user-info rounded-0">
                <div className="meta-info">
                    <div className="author">
                        <div className="avatar">
                            <img src="https://i.ibb.co.com/k30GvPB/vespuch.jpg"  alt={name ? name : 'empty'}/>
                        </div>
                        <div className="info">
                            <span>{getGreeting()}</span>
                            <h6>{name}</h6>
                        </div>
                    </div>
                    <div className="d-flex">
                        <span className='hover-pointer' onClick={() =>  logout()}>
                            <svg fill="#FFFFFF" width="3.5em" height="3.5em" viewBox="-5.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <title>off</title>
                                <path d="M14.344 7.375c3.688 1.563 6.281 5.219 6.281 9.5 0 5.656-4.625 10.313-10.313 10.313-5.656 0-10.313-4.656-10.313-10.313 0-4.281 2.594-7.938 6.313-9.5v3.469c-1.938 1.313-3.25 3.5-3.25 6.031 0 4 3.25 7.25 7.25 7.25s7.25-3.25 7.25-7.25c0-2.531-1.281-4.719-3.219-6.031v-3.469zM12.031 16.813v-12.031h-3.438v12.031h3.438z"></path>
                            </svg>
                        </span>
                    </div>
                    {/* <div className="tags">{item.tags}</div> */}
                </div>
               <div className="tags mt-4 hover-pointer mb-2">
                    Change Avatar
               </div>
            </div>
            }
            <div className="widget widget-filter style-1 mgbt-0 mt-5">
                <div className="header-widget-filter">
                    <h4 className="title-widget">Filter</h4>
                </div>
            </div>
            <div className="divider"></div>
            <div className="wrap-category">
                {
                    dataCate.map((item, index) => (
                        <div key={index} className="widget widget-category boder-bt">
                            <Accordion title={item.title} show={true}>
                                <form action="#">
                                    {
                                        item.content.map((itemm, index) => (
                                            <div key={index}>
                                                <label>
                                                    <span>{itemm.field}</span>
                                                    <span className="pst-re">
                                                        <input type="checkbox" onChange={(event) => handleRadio(event, itemm.field)} />
                                                        <span className="btn-checkbox"></span>
                                                    </span>
                                                </label><br />
                                            </div>
                                        ))
                                    }
                                </form>
                            </Accordion>
                        </div>
                    ))
                }

            </div>
        </div>
    );
}

export default SideBar;