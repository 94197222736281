
export const formatCurrency = (value) => {

    // Dollar format, without cent
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits:0}).format(value);
}

export function getGreeting() {
    const hour = new Date().getHours();
    
    if (hour < 12) {
      return "Good Morning";
    } else if (hour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }