import React , { useState , Fragment , useRef} from 'react'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import Countdown from "react-countdown";
import CardModal from '../CardModal';
import { formatCurrency } from '../../../utils/generalHelper';


const ItemContent = ({dataPanels}) => {
    const [modalData, setModalData] = useState(null);
    const [dataTab] = useState(
        [
            {
                id: 1,
                title: "All Items",
            }
        ]
    )
    
    const [visible , setVisible] = useState(15);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 5);
    }

    // const listBtn = useRef(null)
    // const gridBtn = useRef(null)
    const listContent = useRef(null)
    const gridContent = useRef(null)

    // const listToggle = () => {
    //     listBtn.current.classList.add('active');
    //     gridBtn.current.classList.remove('active');
    //     listContent.current.classList.add('open');
    //     gridContent.current.classList.remove('open');
    // }
    // const gridToggle = () => {
    //     gridBtn.current.classList.add('active');
    //     listBtn.current.classList.remove('active');
    //     gridContent.current.classList.add('open');
    //     listContent.current.classList.remove('open');
    // }

    const [modalShow, setModalShow] = useState(false);
    const handleOpenModal = (item) => {
        setModalData(item); // Set the item data to be displayed in the modal
        setModalShow(true); // Show the modal
    };
  return (
    <Fragment>
        <div className="flat-tabs items">

            <Tabs >
                <TabList>
                    {
                        dataTab.map(data=> (
                            <Tab key={data.id} >{data.title}</Tab>
                        ))
                    }
                </TabList>
                {
                    dataPanels.map(data =>(

                        <TabPanel key={data.id}>
                            <div className="option">
                                {/* <h2 className="title">1,000 Items</h2> */}
                                <div className="view">
                                    
                                </div>
                            </div>
                            
                            <div className="content-item open pt-0"  ref={listContent}>
                                {
                                    data.dataContent.slice(0,visible).map ((item,key)=>(
                                        <div key={key} className="col-item" onClick={() => handleOpenModal(item)}>
                                            <div className="sc-card-product menu_card style-h7">
                                                <div className="meta-info style">
                                                    <div className="author">
                                                        <div className="avatar">
                                                            <img src={item.image_link} alt="Axies" />
                                                        </div>
                                                        <div className="info">
                                                            <span>Seller</span>
                                                            <h6> <Link to="$">{item.seller_name}</Link> </h6>
                                                        </div>
                                                    </div>
                                                    {/* <button className="wishlist-button heart"><span className="number-like"> {item.wishlist}</span></button> */}
                                                </div>
                                                <div className="card-media">
                                                    <Link to="#"><img src={item.image_link} alt="Axies" /></Link>
                                                    {/* <div className="featured-countdown">
                                                        <span className="slogan"></span>
                                                        <Countdown date={Date.now() + 500000000}>
                                                            <span>You are good to go!</span>
                                                        </Countdown>
                                                    </div> */}
                                                    <div className="button-place-bid">
                                                        <button onClick={() => handleOpenModal(item)} data-toggle="modal" data-target="#popup_bid" className="sc-button style-place-bid style bag fl-button pri-3"><span>Process Buy</span></button>
                                                    </div>
                                                </div>
                                                <div className="card-title">
                                                    <h5><Link to="#">{item.title}</Link></h5>
                                                </div>
                                                <div className="meta-info">
                                                    <div className="author">
                                                        <div className="info">
                                                            <span>Price </span>
                                                            <span className="pricing">{formatCurrency(item.price)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="tags">{item.category}</div>
                                                </div>
                                            </div>   
                                        </div>
                                    ))
                                }

                                {
                                    data.dataContent.length === 0 && 
                                        <div className="col-12">
                                            <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">
                                                Sorry, We Couldn’t Find Any Results For This Search.
                                            </h2>
                                        </div>
                                    
                                }

                                {
                                    visible < data.dataContent.length && 
                                    <div className="col-md-12 wrap-inner load-more text-center btn-auction item center">
                                        <Link to="#" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                                    </div>
                                }
                            </div>
                            <div className="content-item2" ref={gridContent}>
                                {
                                    data.dataContent.slice(0,visible).map((item,index)=> (
                                    <div key={index} className="col-item">
                                        <div className="sc-card-product menu_card style-h7">
                                            <div className="wrap-media">
                                                <div className="card-media">
                                                    <Link to="/item-details-01"><img src={item.img} alt="Axies" /></Link>                                                                                                            
                                                </div>
                                            </div>
                                            <div className="card-title">
                                                <p>Item Name</p>
                                                <h4><Link to="/item-details-01">{item.title}</Link></h4>
                                            </div>
                                            <div className="meta-info style">
                                                <p>Creator</p>
                                                <div className="author">
                                                    <div className="avatar">
                                                        <img src={item.imgAuthor} alt="Axies" />
                                                    </div>
                                                    <div className="info">
                                                        <h4> <Link to="author02.html">{item.nameAuthor}</Link> </h4>
                                                    </div>
                                                </div>
                                            </div>     
                                            <div className="countdown">
                                                <p>Countdown</p>
                                                <div className="featured-countdown">
                                                    <span className="slogan"></span>
                                                    <Countdown date={Date.now() + 500000000}>
                                                        <span>You are good to go!</span>
                                                    </Countdown>
                                                </div>
                                            </div>
                                            <div className="wrap-hear">
                                                <button className="wishlist-button heart"><span className="number-like"> {item.wishlist}</span></button>
                                            </div>
                                            <div className="wrap-tag">
                                                <div className="tags">{item.tags}</div>
                                            </div>
                                            <div className="meta-info">
                                                <div className="author">
                                                    <div className="info">
                                                        <p>Current Bid</p>
                                                        <p className="pricing">{item.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="button-place-bid">
                                                <button onClick={() => handleOpenModal(item)} data-toggle="modal" data-target="#popup_bid" className="sc-button style-place-bid style bag fl-button pri-3"><span>Place Bid</span></button>
                                            </div>
                                        </div>   
                                    </div>
                                    ))
                                }
                                {
                                    visible < data.dataContent.length && 
                                    <div className="col-md-12 wrap-inner load-more text-center btn-auction item center">
                                        <Link to="#" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                                    </div>
                                }
                            </div>
                            
                        </TabPanel>
                    ))

                }
            </Tabs>
        </div>
        <CardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={modalData}
         />        
    </Fragment>
  )
}

export default ItemContent